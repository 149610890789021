.herospace {
    width: 100%;
    height: 280px;
    display: block;
    background: url(../img/hero-bg-2.jpg) fixed no-repeat;

    .wrapper {
        height: 280px;
        display: flex;
        align-items: center;
    }

    .hero-wrapper {
        width: 100%;
        text-align: center;
        color: $bwhite;
        text-shadow: 1px 3px rgba($bblack, .35);

        h1 {
            font-size: 2.4em;
            font-weight: 300;
            text-align: center;
            line-height: 1.4em;
        }

        .headline {
            font-size: 2.2em;
            font-weight: 300;
            line-height: 1.3em;
            margin-top: .25em;
        }

        .quote {
            width: 70%;
            font-size: 1.2em;
            line-height: 1.3em;
            margin: 1.25em 15% 0 15%;
        }
    }
}

.slideshow {
    width: 100%;
    display: block;
}

.cta-cols {
    width: 100%;
    display: block;
    padding: 2em 0;

    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .cta-article {
        width: 30%;
        display: flex;
        flex-direction: column;
        margin: 1em;
        &:first-of-type { margin-left: 0; }
        &:last-of-type { margin-right: 0; }

        h2 {
            display: block;
            a {
                font-size: 2em;
                font-weight: 300;
                color: $bsecondary;
            }
        }

        .cta-image {
            display: block;
            margin: 1em 0;
            padding: 1em 0;
            border-top: 2px dotted $glight;
            border-bottom: 2px dotted $glight;
            text-align: center;
            overflow: hidden;
            img { width: 100%; height: auto; margin: 0 auto; }
        }

        .body-copy {
            min-height: 3em;
            display: block;
            line-height: 1.5em;
        }

        .cta-btn {
            display: block;
            margin-top: .5em;
            padding: .5em 0;
            .btn { display: inline-block; }
        }
    }
}

.brand-section {
    width: 100%;
    display: block;
    padding: 1em 0 2em 0;

    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .brand-intro {
        flex: 1;

        h2 {
            display: block;
            font-size: 2em;
            font-weight: 300;
            color: $bsecondary;
            margin-bottom: .5em;
            padding-bottom: .5em;
            border-bottom: 2px dotted $glight;
        }

        h4 {
            display: block;
            font-size: 1.4em;
            line-height: 1.5em;
            color: $bprimary;
            margin-bottom: .5em;
        }

        .page-body {
            flex: 1;
            display: block;
            line-height: 1.5em;
            p, div { font-size: 1.1em; }
        }

        .brand-image {
            flex-basis: 35%;
            text-align: center;
            margin-right: 2em;
            img {
                width: 100%;
                border: 7px solid $bwhite;
                box-shadow: 0 2px 10px rgba($bblack, .2);
            }
        }
    }

    .brand-intro-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .brand-social {
        width: 340px;
        margin-left: 4em;
    }
}
