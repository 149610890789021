.pagination {
    width: 100%;
    text-align: center;
    margin: 2em 0;
    padding: 1em;
    background: $glightest;
    display: flex;
    justify-content: center;

    li {
        display: inline-block;
        margin: 0 .5em;
        list-style: none;
        font-family: $base-font-family;
        a {
            color: $bprimary;
            font-size: 17px;
            font-weight: $fw-light;
            padding: .25em .6em;
            border: none;
            background: $bwhite;
            &:hover { color: $bwhite; background: $bprimary; text-decoration: none; }
        }

        &.active a {
            color: $bwhite;
            border: none;
            background: $bsecondary;
        }

        &.disabled a {
            cursor: not-allowed;
            color: $bwhite;
            border: none;
            background: lighten($bprimary, 20%);
        }

        &:first-child {
            margin-left: 0;
            a { color: $bwhite; background: none; }
        }
        &:last-child {
            margin-right: 0;
            a { color: $bwhite; background: none; }
        }
    }
}