$slick-loader-path: "../img/";
$slick-font-path: "../fonts/";

@import "./vendor/bower_components/font-awesome/scss/font-awesome";
@import "./vendor/bower_components/md-compass-mixins/lib/compass";
@import "./vendor/bower_components/slick-carousel/slick/slick.scss";
@import "./vendor/bower_components/slick-carousel/slick/slick-theme.scss";
@import "reset";
@import "variables";
@import "mixins";

body {
    font-family: $base-font-family;
    font-size: $base-font-sizepx;
    color: $tc-base;
    background: $body-bgc;
    text-align: center;
    overflow-y: scroll;
}

.wrapper {
    width: $base-wrapper;
    position: relative;
    margin: 0 auto;
    text-align: left;
}

@import "ui";
@import "layout";
@import "lightbox";

@import "modules/home";
@import "modules/page";
@import "modules/reference";
@import "npsb-modules/breadcrumb";
@import "npsb-modules/pagebody";
@import "npsb-modules/pagination";

@import "mediaqueries";
