/////////////////////////////////////////
// Declare project variables           //
/////////////////////////////////////////

// Base variables
$base-wrapper:              1140px;
$body-bgc:                  #fff;

// Brand Colors
$bwhite:                    #fff;
$bblack:                    #4e4e4e;

$bprimary:                  #75b22b;
$bprimary-light:            lighten($bprimary, 5%);
$bprimary-dark:             darken($bprimary, 5%);

$bsecondary:                #522f11;
$bsecondary-light:          lighten($bsecondary, 5%);
$bsecondary-dark:           darken($bsecondary, 5%);

$bhighlight:                $bprimary;

$bsuccess:             		#94b833;
$bwarning:             		#edce41;
$bdanger:              		#df1b24;

$gbase:                     #868686;
$gdark:                     #6e6e6e;
$gdarker:                   #3c3c3c;
$gdarkest:                  #4a4a4a;
$glight:                    #d9d9d9;
$glighter:                  #e8e8e8;
$glightest:                 #f6f6f6;

$tc-link:                   $bhighlight;

// Font declarations

@font-face {
    font-family: 'LatoWeb';
    src: url('../fonts/LatoLatin-Light.eot');
    src: url('../fonts/LatoLatin-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/LatoLatin-Light.woff2') format('woff2'),
         url('../fonts/LatoLatin-Light.woff') format('woff'),
         url('../fonts/LatoLatin-Light.ttf') format('truetype'),
         url('../fonts/LatoLatin-Light.svg#LatoWeb') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('../fonts/LatoLatin-Regular.eot');
    src: url('../fonts/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/LatoLatin-Regular.woff2') format('woff2'),
         url('../fonts/LatoLatin-Regular.woff') format('woff'),
         url('../fonts/LatoLatin-Regular.ttf') format('truetype'),
         url('../fonts/LatoLatin-Regular.svg#LatoWeb') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('../fonts/LatoLatin-Bold.eot');
    src: url('../fonts/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/LatoLatin-Bold.woff2') format('woff2'),
         url('../fonts/LatoLatin-Bold.woff') format('woff'),
         url('../fonts/LatoLatin-Bold.ttf') format('truetype'),
         url('../fonts/LatoLatin-Bold.svg#LatoWeb') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('../fonts/LatoLatin-Black.eot');
    src: url('../fonts/LatoLatin-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/LatoLatin-Black.woff2') format('woff2'),
         url('../fonts/LatoLatin-Black.woff') format('woff'),
         url('../fonts/LatoLatin-Black.ttf') format('truetype'),
         url('../fonts/LatoLatin-Black.svg#LatoWeb') format('svg');
    font-weight: 700;
    font-style: normal;
}


$fw-hairline:           100;
$fw-light:              300;
$fw-regular:            400;
$fw-semibold:           600;
$fw-bold:               700;
$fw-extrabold:          800;

$fwh:					$fw-hairline;
$fwl:					$fw-light;
$fwr:					$fw-regular;
$fws:					$fw-semibold;
$fwb:					$fw-bold;
$fwe:					$fw-extrabold;

// Base Font
$tc-base:               $gdarkest;
$base-font-size:        15;
$base-font-sizepx:      15px;
$base-line-height:      1.5em;
$base-font-family:      'LatoWeb', Helvetica, Arial, sans-serif;
$base-font-weight:      $fw-regular;
$bfaf:                  $base-font-family;

$icon-font-family:      'FontAwesome';
$ifaf:                  $icon-font-family;

// Fixed Font
$fixed-font-size:       85%;
$fixed-font-family:     monospace;
$fixed-line-height:     $base-line-height;

// Font sizes
@function em($target, $context: $base-font-size) {
    @return ($target / $context) * 1em;
}

// Responsive breakpoints
$screen-xs:             500px;
$screen-sm:             700px;
$screen-md:             1000px;
$screen-lg:             1300px;