.references-container {
    width: 85%;
    display: block;
    padding: .1em 0;
    margin: 0 auto;
}

.reference-types {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .type-col {
        width: 45%;
        position: relative;
        margin: 0 1em;
        padding: .25em;
        display: inline-block;
        border: 1px solid $glightest;
        box-shadow: 0 0 10px rgba($bblack, .25);

        &:nth-child(1) {
            h2 { background: $bprimary; }
            &:hover h2 { background: lighten($bprimary, 5%); }
        }
        &:nth-child(2) {
            h2 { background: $bsecondary; }
            &:hover h2 { background: lighten($bsecondary, 5%); }
        }

        h2 {
            position: absolute;
            width: 97.5%;
            display: block;
            font-size: 1.4em;
            font-weight: bold;
            color: $bwhite;
            text-transform: uppercase;
            text-shadow: 1px 1px rgba($bblack, .2);
            padding: 1.2em 0;
            text-align: center;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

.references-grid {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 1.5em 0;

    .reference-item {
        width: 30%;
        position: relative;
        display: inline-block;
        margin: 1em;

        h2 {
            width: 100%;
            display: block;
            padding: 1.2em 1em;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($glightest, .9);
            a { font-size: 1.1em; font-weight: bold; color: $bsecondary; line-height: 1.3em; }
        }

        .img-container {
            width: 100%;
            display: block;
            text-align: center;
            overflow: hidden;

            img { width: 100%; height: auto; }
        }

        &:hover {
            h2 { background: $glightest; }
        }
    }
}

.references-filter {
    width: 100%;
    display: flex;
    margin-top: 1.5em;
    padding: 1em;
    justify-content: flex-start;
    background: $glightest;
    border-bottom: 3px solid darken($glightest, 5%);

    p {
        font-weight: bold;
        display: inline-block;
        color: $bsecondary;
        margin-left: 1em;
        line-height: 34px;
    }

    select {
        min-width: 31%;
        height: 34px;
        font: normal .9em $bfaf;
        color: $bsecondary;
        margin-left: 3em;
        background: $bwhite;
        border: 1px solid tint($bsecondary, 50%);
    }
}

.reference-single-container {
    width: 100%;
    display: block;
    padding: 1em 0 3em 0;
}

.reference-single {
    width: 100%;
    position: relative;
    display: block;
    margin-top: 1em;

    .reference-bg {
        width: 100%;
        height: auto;
        max-height: 700px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .reference-lead {
        width: 100%;
        display: block;
        position: relative;
        z-index: 50;
        background: rgba(0, 0, 0, .5);
        padding: 2em .5em 5em .5em;

        h1.page-title {
            display: block;
            font-size: 3.3em;
            font-weight: 300;
            color: $bwhite;
            line-height: 1.5em;
            margin-bottom: .35em;
        }

        .lead {
            display: block;
            font-size: 1.3em;
            line-height: 1.7em;
            color: $bwhite;
        }
    }

    .reference-gallery {
        width: 80%;
        height: 580px;
        position: relative;
        display: block;
        z-index: 50;
        box-shadow: 0 0 5px rgba($bblack, .3);
        margin: -3em 10% 2em 10%;

        .reference-slideshow {
            width: 100%;
            height: 580px;
            position: relative;
            display: block;
            background: rgba($bwhite, .97);
            border: 20px solid rgba($bwhite, .5);
            margin-bottom: 0;

            .ss-open-label {
                display: block;
                color: $bwhite;
                font-size: 1em;
                position: absolute;
                right: 3em;
                bottom: 0;
                z-index: 200;
                background: rgba(0, 0, 0, .5);
                padding: 1.25em 1.5em;
                &:hover { background: rgba(0, 0, 0, .6); }
            }

            .slick-el {
                width: 100%;
                height: 540px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                text-align: center;
                opacity: .2;
                &.slick-current { opacity: 1; }
            }

            img {
                width: auto;
                height: 540px;
                margin: 0 auto;
                display: block;
                border: none;
            }

            .slick-prev, .slick-next {
                z-index: 100;
                &:before {
                    font-family: "FontAwesome";
                    font-size: 42px;
                    text-shadow: 2px 2px 12px $bblack;
                }
            }

            .slick-prev {
                left: 35px;
                &:before { content: '\f053'; }
            }

            .slick-next {
                right: 35px;
                &:before { content: '\f054'; }
            }
        }
    }

    .reference-body {
        margin-top: 2em;

        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0 2.8em;
        }

        .reference-body-col {
            width: 47%;
            display: inline-block;
            padding: 0 1em;

            .page-body {
                font-size: 1.15em;
                font-style: italic;
            }

            article.person {
                background: $glightest;
                border: 1px solid $glight;
            }
        }
    }
}
