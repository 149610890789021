// Page elements
.page-container {
    width: 100%;
    display: block;
    padding: 1em 0 3em 0;

    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .page-lead {
        p, div { font-size: 1.1em; font-weight: bold; }
    }

    .page-single {
        flex: 1;
        padding-top: .5em;

        h1.page-title {
            display: block;
            font-size: 40px;
            font-weight: 300;
            color: $bsecondary;
            border-bottom: 2px dotted $glight;
            padding-bottom: .5em;
            margin: .25em 0 .5em 0;
        }

        .page-date {
            display: block;
            font-size: 1.1em;
            font-weight: bold;
            color: $gbase;
            margin-bottom: 1em;
            &:before { content: '\f017'; font-family: $ifaf; margin-right: 5px; }
        }

        .page-tags {
            color: $gbase;
            display: block;
            &:before { content: '\f02b'; font-family: "FontAwesome"; margin-right: .5em; }

            a {
                color: $bprimary;
                &:hover { text-decoration: underline; }
                &:after { content: ', '; }
                &:last-child:after { display: none; }
            }
        }

        .contact-page-body {
            .map-container {
                width: 400px;
                height: 400px;
                float: right;
                margin: 0 0 1em 1em;

                #map { width: 400px; height: 400px; }
            }
        }
    }

    .page-sidebar {
        width: 350px;
        display: inline-block;
        margin-left: 3em;

        .sidebar-box {
            display: block;
            background: $glightest;
            border: 1px solid darken($glightest, 7%);

            .sidebar-title {
                display: block;
                font-size: 36px;
                font-weight: 300;
                color: $bsecondary;
                padding: .75em .5em;
            }
        }

        .magazine-item {
            display: block;

            .magazine-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: $bwhite;

                img {
                    width: 120px;
                    height: 120px;
                    display: inline-block;
                    margin-right: 1.25em;
                }

                h3 {
                    flex: 1;
                    font-size: 1.2em;
                    font-weight: bold;
                    line-height: 1.5em;
                    a {
                        color: $bsecondary;
                        &:hover { text-decoration: underline; }
                    }
                }
            }

            .magazine-lead {
                display: block;
                padding: 1.5em 1.5em 2em 1.5em;
            }
        }

        .sidebar-popular-box {
            margin: 1.5em 0;

            .magazine-item {
                margin-bottom: 1em;
                &:last-child { margin-bottom: 0; }
            }
        }
    }
}

// Listing nodes
.node-list {
    width: 100%;
    display: block;

    .node-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1em;
        margin: 1.5em 0;
        border-bottom: 2px dotted $glight;

        .img-container {
            width: 150px;
            display: inline-block;
            margin-right: 1.5em;
            img { width: 100%; height: auto; display: block; }
        }

        .node-content {
            flex: 1;

            h2 {
                display: block;
                margin-bottom: 1em;
                a {
                    font-size: 1.3em;
                    font-weight: bold;
                    color: $bsecondary;
                    &:hover { text-decoration: underline; }
                }
            }

            .page-body {
                display: block;
                margin-bottom: .75em;
            }

            .btn { display: inline-block; }
        }
    }
}

// Person
article.person {
    display: flex;
    padding: 1em;
    justify-content: space-between;

    .img-container {
        width: 130px;
        display: inline-block;
        margin-right: 1.25em;
        img { width: 100%; }
    }

    .person-details {
        flex: 1;

        h3 {
            font-size: 1.2em;
            font-weight: bold;
            line-height: 1.5em;
            color: $bsecondary;
        }

        p {
            font-size: .9em;
            line-height: 1.3em;
            margin: .5em 0;
            &.work-title { font-style: italic; }
            &.lead { color: $bprimary; }
            &.phone {
                &:before { content: '\f095'; font-family: $ifaf; color: $glight; margin-right: 5px; }
            }
            &.email {
                &:before { content: '\f1d8'; font-family: $ifaf; color: $glight; margin-right: 5px; }
                a {
                    color: $bblack;
                    text-decoration: underline;
                    &:hover { color: lighten($bblack, 10%); }
                }
            }
        }
    }
}

// About us
.about-grid {
    width: 100%;
    display: block;
    margin: 3em 0 1em 0;

    .about-title {
        display: block;
        font-size: 2.2em;
        font-weight: 300;
        color: $bsecondary;
        margin-bottom: .5em;
        padding-bottom: .25em;
        border-bottom: 2px dotted $glight;
    }

    .about-items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        article.person {
            width: 50%;
            &:nth-of-type(odd) { padding-left: 0; }
            &:nth-of-type(even) { padding-right: 0; }

            .img-container {
                width: 130px;
                display: inline-block;
                margin-right: 1.25em;
                img { width: 100%; }
            }

            .person-details {
                flex: 1;

                h3 {
                    font-size: 1.2em;
                    font-weight: bold;
                    line-height: 1.5em;
                    color: $bsecondary;
                }

                p {
                    font-size: .9em;
                    line-height: 1.3em;
                    margin: .5em 0;
                    &.work-title { font-style: italic; }
                    &.lead { color: $bprimary; }
                    &.phone {
                        &:before { content: '\f095'; font-family: $ifaf; color: $glight; margin-right: 5px; }
                    }
                    &.email {
                        &:before { content: '\f1d8'; font-family: $ifaf; color: $glight; margin-right: 5px; }
                        a {
                            color: $bblack;
                            text-decoration: underline;
                            &:hover { color: lighten($bblack, 10%); }
                        }
                    }
                }
            }
        }
    }
}

.person-tiny {
    max-width: 60%;
    background: $glightest;
    border: 1px solid $glight;
    padding: .5em;
    margin-top: 1.5em;

    .img-container {
        margin-bottom: 0;
    }

    .person-details {
        h3 { font-size: 1.4em !important; }
        .work-title { font-style: normal !important; font-size: 1em; }
    }
}
