/////////////////////////////////
// Mixins and Functions        //
/////////////////////////////////

@mixin linear-gradient($from-color, $to-color) {
	background-color: $from-color;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from-color), to($to-color));
	background-image: -webkit-linear-gradient($from-color, $to-color);
	background-image:    -moz-linear-gradient($from-color, $to-color);
	background-image:     -ms-linear-gradient($from-color, $to-color);
	background-image:      -o-linear-gradient($from-color, $to-color);
	background-image:         linear-gradient($from-color, $to-color);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$from-color}', EndColorStr='#{$to-color}');
}

@mixin circle($width, $color) {
	width: $width;
	height: $width;
	background: $color;
	-webkit-border-radius: $width/2;
	   -moz-border-radius: $width/2;
	        border-radius: $width/2;
}

@mixin circle-pc($width, $color) {
    position: relative;
    width: $width;
    height: 0;
    padding: $width/2 0;
    background: $color;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}

@mixin hover($color) {
	&:hover { background-color: shade($color, 10%); }
}

@mixin wf($w: 100%, $f: left) {
    width: $w;
    float: $f;
}

@mixin lund($color: $bblack) {
    a { color: $color; &:hover { text-decoration: underline; } }
}

@mixin lcol($color: $bblack, $percent: 15%) {
    a {
        color: $color;
        &:hover { color: shade($color, $percent); }
        @include transition(color, 0.15s, ease-in-out);
    }
}

@mixin vp($name, $argument) {
    -webkit-#{$name}: #{$argument};
    -moz-#{$name}: #{$argument};
    #{$name}: #{$argument};
}

@mixin bb {
    @include vp(box-sizing, border-box);
}

@mixin column-count($columns) {
    @include vp(column-count, $columns);
}

@mixin rotate($degrees) {
    -webkit-transform: rotate(#{$degrees}deg);
       -moz-transform: rotate(#{$degrees}deg);
        -ms-transform: rotate(#{$degrees}deg);
         -o-transform: rotate(#{$degrees}deg);
            transform: rotate(#{$degrees}deg);

    filter:  progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)});
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
    zoom: 1;
}

@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
       -moz-transition: $transition-property $transition-time $method;
        -ms-transition: $transition-property $transition-time $method;
         -o-transition: $transition-property $transition-time $method;
            transition: $transition-property $transition-time $method;
}

@mixin triangle($direction: "down", $size: 20px, $color: #000) {
    width: 0; 
    height: 0;
    border-bottom: $size solid #{set-triangle-color($direction, "bottom", $color)};
     border-right: $size solid #{set-triangle-color($direction, "right", $color)};
      border-left: $size solid #{set-triangle-color($direction, "left", $color)};
       border-top: $size solid #{set-triangle-color($direction, "top", $color)};
}

// Right-Angled Triangle
@mixin triangle-ra($width, $height, $direction, $color) {
    width: 0;
    height: 0;
    border-style: solid;

    @if $direction == bottom-left{
        border-color: transparent transparent transparent $color;
        border-width: $height 0 0 $width;
    }

    @if $direction == bottom-right {
        border-color: transparent transparent $color transparent;
        border-width: 0 0 $height $width;
    }

    @if $direction == top-left {
        border-color: $color transparent transparent transparent;
        border-width: $height $width 0 0;
    }

    @if $direction == top-right {
        border-color: transparent $color transparent transparent;
        border-width: 0px $width $height 0px;
    }
}
 
@function set-triangle-color($direction, $side, $color) {
    @if $direction == "left" and $side == "right" 
     or $direction == "right" and $side == "left" 
     or $direction == "down" and $side == "top" 
     or $direction == "up" and $side == "bottom" {
        @return $color
    } @else {
        @return "transparent";
    }
}