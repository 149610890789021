.breadcrumb {
    width: 100%;
    display: block;

    span {
        margin-right: .5em;
        display: inline;
        color: $bblack;
        font-size: 15px;
        text-decoration: none;
        line-height: 1.3em;
        &:before {
            color: $bblack;
            margin-right: .75em;
            content: '\f105';
            font-family: $icon-font-family;
        }
        a { color: $bblack; text-decoration: none; }
        &:first-child:before { display: none; }
    }
}
