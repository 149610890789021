// Header section
.site-header {
    width: 100%;
    height: 130px;
    position: relative;
    display: block;
    background: #fafafa;
    
    .wrapper {
        height: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .logo {
        width: 140px;
        height: 90px;
        display: inline-block;
        background: url(../img/logo-transparent.png) no-repeat;
        background-size: cover;
    }

    .menu-toggler {
        flex: 1;
        font-size: 1.3em;
        font-weight: bold;
        color: $bprimary;
        margin-left: 1.5em;
        text-transform: uppercase;
        text-align: right;
        display: none;
    }

    .nav-menu {
        display: inline-block;
        height: 34px;
        margin-top: 3px;

        ul {
            li {
                display: inline-block;
                padding: 0 .5em;
                a {
                    font-size: .95em;
                    position: relative;
                    display: inline-block;
                    text-transform: uppercase;
                    color: $bblack;
                    padding: .5em .4em;
                    &:hover { color: lighten($bblack, 12%); }
                    &:after {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 30px;
                        position: absolute;
                        top: 0;
                        right: -.75em;
                        border-left: 2px dotted $glight;
                    }
                }
                &:last-child a:after { display: none; }
            }
        }
    }

    .search-widget {
        width: 150px;
        height: 36px;
        position: relative;
        display: inline-block;

        .search-input {
            width: 150px;
            height: 34px;
            font: normal 13px $bfaf;
            padding: .5em 3em .5em .5em;
            border: 1px solid $glight;
            border-radius: 5px;
            outline: none;
        }

        .search-btn {
            background: none;
            border: none;
            position: absolute;
            top: .1em;
            right: .15em;
            font-size: 1.25em;
            color: $bprimary;
            cursor: pointer;
        }
    }
}

// Main wrappers
.main-container {
    width: 100%;
    min-height: 400px;
    display: block;
}

// Footer section
.site-footer {
    width: 100%;
    display: block;
    background: lighten($glight, 5%);
    margin-top: 1em;

    .footer-info {
        width: 100%;
        display: block;
        padding: 3em 0;
        @include linear-gradient($glight, $glightest);

        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .footer-menu {
                width: 20%;
                display: inline-block;

                li {
                    padding: 1em 0 1em 1em;
                    border-bottom: 2px dotted darken($glight, 5%);
                    &:last-child { border-bottom: none; }
                    a {
                        color: $bblack;
                        text-transform: uppercase;
                        &:hover { color: lighten($bblack, 10%); }
                    }
                }
            }

            .office-image {
                max-width: 280px;
                height: auto;
                display: inline-block;
                margin: 0 4em 0 6em;
            }

            .contact {
                p {
                    font-size: .95em;
                    line-height: 1.5em;
                    margin-bottom: .75em;
                    &.address { font-size: 1.35em; color: $bprimary; }
                    a { color: $bblack; text-decoration: underline; }
                }

                h5 {
                    font-size: 1.2em;
                    line-height: 1.5em;
                    color: $bprimary;
                }
            }
        }
    }

    .footer-legal {
        width: 100%;
        display: block;
        padding: 1em 0;
        font-size: .9em;
    }
}