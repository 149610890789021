.page-body {
    color: $bblack;
    font-size: 15px;
    font-weight: normal;
    font-family: $bfaf;
    line-height: 1.5em;

    div, p {
        color: $bblack;
        font-size: 15px;
        font-weight: normal;
        line-height: 1.5em;
        margin-bottom: 1em;
    }

    h2, h3, h4, h5, h6 {
        color: $bprimary;
        font-family: $bfaf;
        font-weight: bold;
        line-height: 1.5em;
        margin-top: .7em;
        margin-bottom: .5em;
        a {
            text-decoration: none;
            &:hover { text-decoration: underline; }
        }
    }

    h2 { font-size: 2em; }
    h3 { font-size: 1.8em; }
    h4 { font-size: 1.6em; }
    h5 { font-size: 1.3em; }
    h6 { font-size: 1.1em; }

    ul, ol {
        width: 96%;
        margin: .8em 0 1.2em 4%;
    }

    ul li, ol li {
        list-style-type: disc;
        font-weight: normal;
    }

    ol li { list-style-type: decimal; }
    ul li {
        list-style: none;
        padding: .35em 0;
        &:before {
            margin-left: -1em;
            margin-right: .5em;
            content: '\f105';
            color: $bprimary;
            font-family: $ifaf;
        }
    }

    a {
        color: $bprimary;
        text-decoration: underline;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: .8em 0 1.2em 0;

        &.table-tiny td {
            font-size: em(13);
            line-height: 1.25em;
            border-bottom: none;
            padding-right: 0;
        }
    }

    table td,
    table th {
        font-size: .8em;
        font-style: normal;
        padding: .35em;
        border-bottom: 1px solid #e1e1e1;
        vertical-align: middle;
    }

    .stressed-text {
        color: $bprimary;
    }

    img {
        max-width: 100%;
        height: auto;

        &[style*="float:left"] {
            border: 5px solid $glightest;
            margin: 0 1em 1em 0;
        }

        &[style*="float:right"] {
            border: 5px solid $glightest;
            margin: 0 0 1em 1em;
        }
    }

    figure.image {
        position: relative;
        max-width: 100%;
        display: block;
        margin: .5em auto;
        &.video-embed {
            padding-bottom: 56.25%;
            padding-top: 25px;
            height: 0;
            iframe {
                position: absolute; top: 0; left: 0;
                width: 100%;
                height: 100%;
            }
        }
        &.open-gallery {
            &:after {
                content: '\f00e';
                position: absolute; right: 20px; top: 20px;
                z-index: 400;
                font-family: $icon-font-family;
                font-size: 32px;
                color: $bwhite;
                text-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
            }
        }
        img {
            max-width: 100%;
            position: relative;
            z-index: 200;
            height: auto;
            display: block;
        }
        figcaption {
            margin: 0;
            padding: 7px;
            background: $glightest;
            font-size: .85em;
            text-align: center;
        }
    }
}