@media only screen and (max-width: 1200px) {

    .wrapper {
        width: 100%;
        padding: 0 1em;
    }

    .site-header {
        .logo { width: 118px; height: 78px; }

        .nav-menu {
            ul {
                li {
                    padding: 0 .45em;
                    a { font-size: .95em; padding: .5em .15em .5em .75em }
                }
            }
        }
    }

}

@media only screen and (max-width: 1040px) {

    .site-header {
        .logo { width: 90px; height: 55px; }

        .nav-menu {
            ul {
                li {
                    padding: 0;
                    a {
                        font-size: .85em; padding-top: .7em;
                        &:after { display: none; }
                    }
                }
            }
        }

        .search-widget {
            width: 100px;
            .search-input { width: 100px; }
        }
    }

    .herospace {
        .hero-wrapper {
            h1 { font-size: 2.6em; }
            p { font-size: 1.5em; }
        }
    }

    .cta-cols {
        .cta-article {
            .body-copy { min-height: 5em; }
        }
    }

    .reference-single {
        .reference-gallery {
            height: 510px;
            .reference-slideshow {
                height: 510px;
                .slick-el { height: 510px; }
                img { height: 470px; }
                .ss-open-label { display: none; }
            }
        }
    }

    .references-grid {
        .reference-item {
            margin: .5em;
        }
    }

    .lb-outerContainer:after {
        width: 75px;
        height: 55px;
        background-position: 7px 4px;
    }

}

@media only screen and (max-width: 800px) {

    .site-header {
        .logo { width: 118px; height: 78px; }

        .menu-toggler {
            display: block;
        }

        .nav-menu {
            display: none;

            &.open {
                width: 100%;
                height: 100%;
                align-items: center;
                flex-wrap: wrap;
                position: fixed;
                top: -3px;
                right: 0;
                padding: 5% 0;
                z-index: 200;
                background: darken(rgba($bprimary, .9), 10%);

                ul {
                    li {
                        width: 100%;
                        margin: 0;
                        padding: .75em;
                        a {
                            display: block;
                            font-size: 1.5em;
                            font-weight: 300;
                            color: $bwhite;
                            text-transform: uppercase;
                            text-align: center;
                        }
                    }
                }

                .close {
                    position: absolute;
                    top: 1em;
                    right: 1em;
                    z-index: 201;
                    font-size: 3em;
                    color: $bwhite;
                    cursor: pointer;
                }
            }
        }

        .search-widget {
            display: none;
        }
    }

    .herospace {
        height: 200px;

        .wrapper { height: 200px; }

        .hero-wrapper {
            h1 { font-size: 2.2em; }
            p { font-size: 1.2em; }
        }
    }

    .cta-cols {
        .cta-article {
            .body-copy { min-height: 6em; }
        }
    }

    .brand-section {
        .wrapper { flex-direction: column; }
        .brand-social { display: none; }
    }

    .page-container {
        .wrapper { flex-direction: column; }
        .page-sidebar { width: 80%; margin: 0 10%; }
        .fb-page { display: none; }
    }

    .reference-types { width: 90%; }

    .references-container { width: 100%; }

    .references-filter {
        flex-direction: column;
        p { margin-left: 0; }
        select { margin-left: 0; }
    }

    .reference-single {
        .reference-gallery {
            width: 100%;
            height: 390px;
            margin: -3em 0 4em 0;

            .reference-slideshow {
                height: 390px;
                border-width: 10px;

                .ss-open-label { display: none; }

                .slick-el { height: 370px; }
                img { height: 370px; }
            }
        }
    }

    .references-grid {
        .reference-item {
            width: 46%;
        }
    }

    .site-footer {
        .footer-info {
            .wrapper {
                .footer-menu { display: none; }
                .office-image { margin: 0 2em 0 0; }
            }
        }
    }

}

@media only screen and (max-width: 600px) {

    .page-container {
        .page-single {
            .contact-page-body {
                .map-container { width: 100%; height: auto; }
            }
        }
        .page-sidebar { margin-top: 2em; }
    }

    .reference-types { width: 100%; }

    .herospace {
        height: auto;

        .wrapper { padding: 1.5em 0; height: auto; }

        .hero-wrapper {
            h1 { font-size: 1.8em; }
            p { font-size: 1.2em; }
        }
    }

    .cta-cols {
        .wrapper { flex-direction: column; }
        .cta-article { width: 100%; margin: 1em 0; }
    }

    .site-footer {
        .footer-info {
            .wrapper {
                .footer-menu { display: none; }
                .office-image { max-width: 170px; }
            }
        }
    }

    .about-grid {
        .about-items {
            flex-direction: column;

            article.person {
                width: 100%;
                margin: .5em 0;
                padding: 0;
            }
        }
    }

    .herospace {
        .hero-wrapper {
            .quote { width: 90%; margin: 1em 5% 0; }
        }
    }

    .cta-cols {
        .cta-article {
            .body-copy { min-height: 0; }
        }
    }

    .brand-section {
        .brand-intro-flex {
            flex-direction: column;
            .brand-image { flex-basis: auto; margin-right: 0; margin-bottom: 1em; }
        }
    }

    .reference-types {
        flex-direction: column;

        .type-col {
            width: 100%;
            min-height: 100px;
            img { display: none; }
        }
    }

    .page-container {
        .page-single {
            h1.page-title { font-size: 30px; }
        }

        .page-sidebar {
            width: 100%;
            margin: 1em 0;
        }
    }

    .reference-single {
        .reference-lead {
            padding-top: 1em;
            padding-bottom: 1em;

            .lead { margin-bottom: 2em; }
            h1.page-title { font-size: 2.6em; line-height: 1.2em; }
        }

        .reference-body {
            .wrapper { flex-direction: column; padding: 0 1em; }
            .reference-body-col { width: 100%; }
        }

        .reference-gallery {
            width: 100%;
            height: 330px;
            margin: 2em 0;

            .reference-slideshow {
                height: 330px;
                .slick-el { height: 310px; pointer-events: none; }
                img { height: 310px; }
            }
        }
    }

    .references-grid {
        flex-direction: column;
        .reference-item { width: 100%; margin: 1em 0; }
    }

    .person-tiny {
        max-width: 100%;
        img { width: 100px; }
        .person-details {
            h3 { font-size: 1.1em; }
            .work-title { font-size: .9em; }
        }
    }

    .site-footer {
        .footer-info {
            .wrapper {
                .office-image { display: none; }
            }
        }
    }

}

@media only screen and (max-width: 500px) {

    .reference-single {
        .reference-gallery {
            width: 100%;
            height: 300px;
            margin: 2em 0;

            .reference-slideshow {
                height: 300px;
                .slick-el { height: 280px; }
                img { height: 280px; }
                .slick-prev { left: 10px; }
                .slick-next { right: 10px; }
            }
        }
    }

}

@media only screen and (max-width: 440px) {

    .reference-single {
        .reference-gallery {
            width: 100%;
            height: 270px;
            margin: 2em 0;

            .reference-slideshow {
                height: 270px;
                .slick-el { height: 250px; }
                img { height: 250px; }
            }
        }
    }

}

@media only screen and (max-width: 320px) {

    .reference-single {
        .reference-gallery {
            width: 100%;
            height: 220px;
            margin: 2em 0;

            .reference-slideshow {
                height: 220px;
                .slick-el { height: 190px; }
                img { height: 190px; }
            }
        }
    }

}
